import * as React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import parse from "html-react-parser"
import Layout from "../components/layout"
import Seo from "../components/seo"
import styled from "styled-components"
import TopSub from "../components/top-sub"
import Container from "../atomic/partials/container"
import { color } from "../components/colors"
import ArticleBox from "../components/article-box"

const Wrapper = styled.div`
  padding-top: 32px;
`

const Path = styled.div`
  display: flex;
  margin-bottom: 64px;
  font-weight: 400;
  text-transform: uppercase;

  a {
    text-transform: uppercase;
    text-decoration: none;
    transition: color 0.3s ease;
    color: #000;
    margin-right: 6px;

    &:hover {
      color: ${color.primary};
    }

    &:not(:first-child) {
      margin-left: 4px;
    }
  }
`

const Box = styled.div`
  width: 100%;
  padding: 40px 15px;

  h1 {
    font-size: 48px;
    line-height: 56px;
    text-align: center;
    margin-bottom: 64px;
  }

  h5 {
    font-size: 20px;
    line-height: 28px;
    color: ${color.grey};
  }

  p {
    font-weight: 400;
    margin-bottom: 32px;
  }

  a {
    color: ${color.primary};
    text-decoration: none;
  }
`

const Post = ({
  data: {
    wpPost: { title, content, categories, id },
  },
}) => (
  <Layout>
    <Seo />
    <TopSub />
    <Container>
      <Wrapper>
        <Path>
          <Link to="/">SM TARAS PÓŁNOC</Link> /{" "}
          <Link to={`/${categories.nodes[0].slug}`}>
            {categories.nodes[0].name}
          </Link>{" "}
          / {title}
        </Path>
        <Box>
          <h1>{parse(title)}</h1>
          <p>{parse(content)}</p>
        </Box>
      </Wrapper>
    </Container>
  </Layout>
)

export default Post

export const query = graphql`
  query($id: String) {
    wpPost(id: { eq: $id }) {
      id
      title
      content
      categories {
        nodes {
          name
          slug
        }
      }
    }
  }
`
